import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { user } from '../model/user';
import { employer } from '../model/employer';
import { AppConstants } from '../appconstants';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private apiUrl = environment.apiUrl;
  public signUpEvent: Subject<any> = new Subject();

  source = [
    { id: 0, name: 'Word of Mouth' },
    { id: 1, name: 'Social Media (e.g., Facebook, Instagram, Twitter)' },
    { id: 2, name: 'Online Advertising (e.g., Google Ads, Facebook Ads)' },
    { id: 3, name: 'Search Engine (e.g., Google, Bing)' },
    { id: 4, name: 'Email Newsletter' },
    { id: 5, name: 'Website Referral (e.g., Blog, Partner Website)' },
    { id: 6, name: 'Traditional Media (e.g., TV, Radio, Print)' },
    { id: 7, name: 'Events or Conferences' },
    { id: 8, name: 'Direct Mail (e.g., Postcards, Brochures)' },
    { id: 9, name: 'Public Relations (e.g., Press Releases, News Articles)' },
    { id: 10, name: 'Community Board or Local Bulletin' },
    { id: 11, name: 'Partnership or Sponsorship' },
  ];

  jobSeekerDetails = {
    data: {
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com',
      zipCode: '90045',
      Phone: '(630) 155-6841',
      ageConfirmation: 'No',
      profilePicture: 'https://picsum.photos/200',
      PriorityIndustry: 1,
    },
  };

  constructor(private http: HttpClient) {}

  getUserProfile(obj: any): Observable<user> {
    if (obj.email) {
      return this.http.get<any>(
        `${this.apiUrl}/user/profile/email/${obj.email}`
      );
    }
    {
      return this.http.get<any>(`${this.apiUrl}/user/profile/id/${obj.userId}`);
    }
  }

  getUserProfileById(id: string): Observable<user> {
    return this.http.get<any>(`${this.apiUrl}/user/profile/id/${id}`);
  }

  getUserProfileByEmail(email: string): Observable<user> {
    return this.http.get<any>(`${this.apiUrl}/user/profile/email/${email}`);
  }

  saveUserProfile(formData: any): void {
    this.http
      .post<any>(`${this.apiUrl}/user`, formData)
      .subscribe((response: any) => {
        let user: any = localStorage?.getItem(AppConstants.USER);
        user = JSON.parse(user ?? '');
        user.userId = response.data;
        localStorage.setItem(AppConstants.USER, JSON.stringify(user));
      });
  }

  updateProfile(formData: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/user`, formData);
  }

  getreferralSource(): Observable<{ data: any[] }> {
    return this.http.get<any>(`${this.apiUrl}/user/lovs`);
  }
  ///////////////////////
  // get employer profile details
  getEmployerDetails(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/employer/A6BDB47A-4F5A-44BC-B7CC-2CC483FE67F6`
    );
  }

  // Save employer details
  saveEmployerDetails(employer: employer): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/employer`, employer);
  }

  getemployerLovs(): Observable<{ data: any[] }> {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/employer/lovs`);
  }
  //permanentDelete(id: string): Observable<any> {
  //  return this.http.delete<{ data: any[] }>(`${this.apiUrl}/employer/${id}`);
  //}

  permanentDelete(userId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/user/${userId}`);
  }

  enableDisableUser(userId: string): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}/user/${userId}`, {});
  }

  getUserClaims(): any {
    let user: any = localStorage?.getItem(AppConstants.USER);
    return JSON.parse(user);
  }
}
